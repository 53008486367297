import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Heading from '../components/heading'
import SEO from '../components/seo'

export default function Company() {
  const title = {
    jp: '会社概要',
    en: 'COMPANY PROFILE'
  }

  const heading = '会社概要'

  const companyItems = [
    {
      id: 'company-item-01',
      label: '会社名',
      contents: '株式会社 サンリク'
    },
    {
      id: 'company-item-02',
      label: '役員氏名',
      contents: '代表取締役　小山田 光幸'
    },
    {
      id: 'company-item-03',
      label: '創立年月日',
      contents: '平成4年4月1日'
    },
    {
      id: 'company-item-04',
      label: '事業内容',
      contents:
        '海産物の卸売および加工販売<br />' +
        '食料品の卸売りおよび販売<br />' +
        '惣菜の製造および販売<br />' +
        '漬物の製造および販売<br />' +
        '日用品雑貨の卸売および販売<br />' +
        '飲食店の経営<br />' +
        '前号に附帯する一切の業務'
    },
    {
      id: 'company-item-05',
      label: '資本金',
      contents: '1,000万円'
    },
    {
      id: 'company-item-06',
      label: '従業員',
      contents: '45名'
    },
    {
      id: 'company-item-07',
      label: '主な取引先',
      contents: '全国食品問屋、スーパーマーケット、量販店'
    },
    {
      id: 'company-item-08',
      label: '取引金融関係',
      contents: '三井住友銀行 西新井支店'
    },
    {
      id: 'company-item-09',
      label: '営業品目',
      contents: 'わかめ（天然・養殖）、昆布、ひじき、もずく、海藻サラダ'
    },
  ]
  return (
    <Layout>
      <SEO title="会社概要 | 株式会社サンリク" />
      <PageTitle titleJP={title.jp} titleEN={title.en} />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        <Heading heading={heading} />

        <table className="mt-4 md:mt-10">
          {companyItems.map(companyItem => (
            <tr key={companyItem.id} className="leading-8 flex md:table-row flex-col">
              <th className="text-left w-40 pt-6 align-top">{companyItem.label}</th>
              <td className="md:pt-6">
                <p dangerouslySetInnerHTML={{ __html: companyItem.contents }} />
              </td>
            </tr>
          ))}
        </table>
      </div>
    </Layout>
  )
}
